/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  LocalInd/LocalIndCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import { useDispatch, useSelector } from 'react-redux'

import { LocalIndcAsyncThunk } from '.';
//import logo from './logo.svg';
//import './App.scss';
//import TheStore from '../../app/store';
//import type { LocalIndStateT } from '.';
//import { LocalIndcSlice } from '.';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function LocalIndcCpF() {
  const dspt = useDispatch();
//const f2dp = ()=>dspt(LocalIndcSlice.actions.run_in_local);
  const f2dp = ()=>dspt(LocalIndcAsyncThunk());
  f2dp();

  const in_local = useSelector((state :any) => state.local_ind.run_in_local);
  if (!in_local)  return (<></>);
/**/
  return (
  <div
    className='LocalIndicator'
    style={{
      position: 'fixed'
    , right: '0.5rem'
    , top: '50vh'
    , color: 'white'
    , backgroundColor: 'red'
    , fontSize: '1.2rem'
    , padding: '0.3rem'
    , zIndex: 9999
    }}
  >
    mamp
  </div>
  );
/**/
}
export default LocalIndcCpF;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// LocalInd/LocalIndCpF.tsx
