/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  localind/_slic_localind.ts
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import {
  createSlice //, configureStore //, PayloadAction
, createAsyncThunk
} from '@reduxjs/toolkit';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


const _fetch_init :RequestInit = {
//  method: 'POST'
      // *GET, POST, PUT, DELETE, etc.
//, mode: 'same-origin'
      // *cors, no-cors, same-origin
//, cache: 'no-cache'
      // *default, no-cache, reload, force-cache, only-if-cached
//, credentials: 'include'
      // *same-origin, include, omit
    headers: {
    'Accept': 'text/plain;charset=utf-8'
  , 'Content-Type': 'text/plain;charset=utf-8'
//, 'Content-Type': 'application/x-www-form-urlencoded'
//, 'Authorization': 'Basic c2FrYWktaDpTa2k4SG50bg=='
  }
//, redirect: 'manual'
      // *follow, manual, error
//, referrerPolicy: 'no-referrer'
      // *no-referrer-when-downgrade, no-referrer, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//, body: JSON.stringify({})
//, body: '0'
      // === headers->Content-Type
  }
;


let _InLocalChecked = false;
let _InLocal = false;

/**
 * /htaccess.mamp (＝MAMP環境のhtaccessバックアップ,冒頭ピリオドなし) が存在するか？
 * 存在すれば、MAMP環境下と判断し、画面内[mamp]表示
 * 
 * @returns boolean
 */
async function _fetch_exist() {
  if (_InLocalChecked)  return _InLocal;

  //console.log('start ajax');
  const rsp :Response = await fetch('/htaccess.mamp', _fetch_init);
  _InLocalChecked = true;

  if (rsp.ok) {
    _InLocal = true;
  } else {
    if (rsp.status === 404)  _InLocal = false;
  }
  return _InLocal;
}


export const LocalIndcAsyncThunk = createAsyncThunk(
  'local_indc/fetch'
, async () => { // payloadCreator
    const in_local = await _fetch_exist();
    return { run_in_local: in_local };
  }
);


// stateの型定義
export type LocalIndcStateT = { run_in_local: boolean; };
const _initState :LocalIndcStateT = { run_in_local: false };


// createSliceでreducerとactionを同時に定義
export const LocalIndcSlice = createSlice({
  name: 'local_indc'
, initialState: _initState
, reducers: {}
, extraReducers: (bldr) => {
/**>/
    bldr.addCase(
      _fetch_thunk.pending
    , (state, action) => {
      //console.log('pending');
      //state.run_in_local = action.payload.run_in_local;
      }
    );
/**/
/**/
    bldr.addCase(
      LocalIndcAsyncThunk.fulfilled
    , (state, action) => {
        //console.log('fulfilled');
        state.run_in_local = action.payload.run_in_local;
      }
    );
/**/
/**>/
    bldr.addCase(
      _fetch_thunk.rejected
    , (state, action) => {
      //console.log('rejected');
      //state.run_in_local = action.payload.run_in_local;
      }
    );
/**/
  }
});


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// localind/_slic_localind.ts
