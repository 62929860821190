/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  ftrbar/LocalIndCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import { useDispatch, useSelector } from 'react-redux';
//import { slide as Menu } from 'react-burger-menu';

//import { LocalIndcAsyncThunk } from '.';
//import logo from './logo.svg';
//import './App.scss';
//import TheStore from '../../app/store';
//import type { LocalIndStateT } from '.';
//import { LocalIndcSlice } from '.';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function FtrBarCpF() {
//const dspt = useDispatch();
//const f2dp = ()=>dspt(LocalIndcSlice.actions.run_in_local);
//const f2dp = ()=>dspt(LocalIndcAsyncThunk());
//f2dp();

//const in_local = useSelector((state :any) => state.local_ind.run_in_local);
//if (!in_local)  return (<></>);
/**/
//<img src={logo} className='App-logo' alt='logo'/>

  return (
  <div id='ftr-bar' className='ftr-bar'>
    <div>&copy;2024 153inc. all rights reserved.</div>
  </div>
  );
}
export default FtrBarCpF;

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// ftrbar/LocalIndCpF.tsx
