/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  gatrk/GATrk.ts
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//import { LocalIndcAsyncThunk } from '.';
//import logo from './logo.svg';
//import 'app/App.scss';
//import TheStore from '../../app/store';
//import type { LocalIndStateT } from '.';
//import { LocalIndcSlice } from '.';

//import X from '../X';
//let _;  X.D = true;  /*eslint-disable no-eval*/
/*
if(X.D){X.L())}               // log
if(X.D){_=``;X.V(_,eval(_))}  // var-name=var-value
if(X.D){_=``;X.T(_,eval(_))}  // var-name=var-value + trace-info
*/
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


/// GTM

export const Ga_TrkId = 'UA-62871571-18';
export const Ga4TrkId = 'G-JYEV9681P3';

declare global { interface Window { gtag: Gtag.Gtag; } }

export function GaTrk(
  trackingId :string|undefined = process.env.GA_MEASUREMENT_ID
, pg_ttl :string = ''
, pg_dsc :string = ''
) {
  if (pg_ttl !== '')  document.title = pg_ttl;
  if (pg_dsc !== '') {
    const metas = document.head.children;
    const meta_sz = metas.length;
    for (let i = 0;  i < meta_sz;  ++i) {
      const meta_i = metas[i];
      if (meta_i.getAttribute('name') === 'description') {
        meta_i.setAttribute('content', pg_dsc);
        break;
      }
    }
  }

  //X.L(useHistory());
  const { listen } = useHistory();
  //if(X.D){_=`listen`;X.V(_,listen)}  // var-name=var-value

  useEffect(
    () => {
      const unlisten = listen((location)=>{
        if (!trackingId) {
          console.log(
            'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
          );
          return;
        }
        window.gtag('config', trackingId, { page_path: location.pathname });
      });
      return unlisten;
    }
  , [trackingId, listen]
  );
}
export default GaTrk;

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// gatrk/GATrk.ts
