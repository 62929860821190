/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  service/PgServiceCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
import { Link } from 'react-router-dom';

import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';

//import logo from 'logo.svg';
//import { OrdersCpF } from 'features/orders';
import TrG from '_img/tra_g.svg';
//import TrW from '_img/tra_w.svg';

import BnC from '_img/btn_contact.svg';

import S1p from '_img/txt_sv01.svg';
import S2p from '_img/txt_sv02.svg';
import S3p from '_img/txt_sv03.svg';
import S4p from '_img/txt_sv04.svg';
import S9p from '_img/txt_sv99.svg';

/**/
import X from 'common/X';  let _;  X.D = true;
/*eslint-disable no-eval*/

/*
X.D&&X.L();              // log
X.D&&X.V(_=``,eval(_));  // var-name=var-value
X.D&&X.T(_=``,eval(_));  // var-name=var-value + trace-info
*/
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8

const _Dsc = [
/*0*/  '' // sentinel
/*1*/, 'ブランディング:ブランドコンセプトを起点にお客さまの想いや考え方に寄り添った提案･制作を通し一緒にブランドを育てていきます。:ネーミング作成、ロゴマーク作成／名刺など企業ツール一式のデザイン／ブランドに関する制作物（webサイト、パッケージ、広告物など）'
/*2*/, 'グラフィックデザイン:ブランドの一貫性を重視したデザイン制作を行っております。ポスターやパネルなど大判印刷の制作も多く携わっております。:冊子（会社案内、学校案内、情報誌など）／ポスター、フライヤー、DM／SPツール、パッケージデザイン、CIロゴ制作／印刷手配代行'
/*3*/, 'ウェブサイト制作:ユーザビリティの高いサイト制作を行っております。コーポレートサイト、ECサイト、SNS運用に携わる広告などさまざまなジャンル用途にご対応可能です。:コンテンツ設計／新規サイト制作、既存サイトのリニューアル／SNSの運用などメディア展開企画／バナー制作'
/*4*/, 'イラスト制作:グラフィックデザインに添えるイラストから等身大キャラパネル、似顔絵などイラスト単体での制作も行っております。お客さまが希望するテイストに合わせたイラストレーションをご提案いたします。:キャラクター制作・挿絵･似顔絵など2Dイラスト全般'
/*5*/, '■主な取引先：凸版印刷株式会社／大平印刷株式会社／株式会社凡美社／株式会社TANAX／株式会社NEXT STAGE／株式会社デザイン･グリッド／株式会社キュービック・アイ／株式会社エム･デザイン／京都女子中学校･高等学校／アインズ株式会社／MTサロン京都駅前店／株式会社坂井本店／松風社／株式会社オム／株式会社京童／探偵さんドットコム／株式会社クエイド／株式会社礎'
];
//X.D&&X.V(_=`_Dsc`,eval(_));


export function PgServiceCpF() {
  
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , '業務内容 : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , '制作実績をご希望の方はcontact（お問い合わせ内容）に「制作実績希望」と入力してください。'
  );

  globalThis.scrollTo(0, 0);  // NG: 遷移時にページ末端に飛ぶ??
//window.scrollTo(0, 0);  // NG: 遷移時にページ末端に飛ぶ??

  return (
    <div id='pg-service' className='pg-service'>

      <h1 className='arwbnd gry'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='ttl1'>service</div>
        <div className='ttl2'>業務内容</div>

        <div className='desc'>
               制作実績をご希望の方は<br className='sm-vis'/>contact（お問い合わせ内容）に
          <br/>「制作実績希望」と入力してください。
        </div>
        <div className='btn-contact'>
          <Link to='/contact'>
            <img src={BnC} alt=''/>
          </Link>
        </div>
      </h1>

      <div className='arwbnd wht'>

{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**/}  <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}

        <div className='txt-sv sv1'>
          <img src={S1p} alt={_Dsc[1]} width='710' height='268'/>
        </div>
        <div className='txt-sv sv2'>
          <img src={S2p} alt={_Dsc[2]} width='710' height='240'/>
        </div>
        <div className='txt-sv sv3'>
          <img src={S3p} alt={_Dsc[3]} width='710' height='274'/>
        </div>
        <div className='txt-sv sv4'>
          <img src={S4p} alt={_Dsc[4]} width='710' height='274'/>
        </div>

        <div className='txt-sv sv8'>
          その他、取材、撮影・ムービー撮影や屋外サインなど
          <br className='sm-vis'/>お気軽にご相談ください。
        </div>
        <div className='txt-sv sv9'>
          <img src={S9p} alt={_Dsc[5]} width='710' height='179'/>
        </div>

      </div>
    </div>
  );
}
export default PgServiceCpF;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// service/PgServiceCpF.tsx
