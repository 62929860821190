/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  app/store.ts
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
//import logo from './logo.svg';
//import './App.scss';
import { configureStore } from '@reduxjs/toolkit';

import { LocalIndcSlice } from '../common/localindc';
//import { AjaxSlice      } from '../common/ajax';
//import { OrdersSlice    } from '../features/orders';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8

export const TheStore = configureStore({
  reducer: {
    local_ind: LocalIndcSlice.reducer
//, ajax: AjaxSlice.reducer
//, orders: OrdersSlice.reducer
  }
});
export default TheStore;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// app/store.ts
