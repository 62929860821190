/* :*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  X.js : utillity Xas
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:: */
/**>/
import X from './X.js';  let _;  X.D = true;  // for js/ts
import X from 'common/X';  let _;  X.D = true;  // for ts
/*eslint-disable no-eval*/

/*
X.D&&X.L();              // log
X.D&&X.V(_=``,eval(_));  // var-name=var-value
X.D&&X.T(_=``,eval(_));  // var-name=var-value + trace-info
*/
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


/**
 * utility Xass
 */
export default class X {
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


//..*....1....*....2....*....3....*....4....*....5....*....6....*....7....*....8
/// debug

/**
 * D)ebugP? :boolean
 * import X from './X';
 * let _;  X.D = true;  /*eslint-disable no-eval* /
 * X.D&&X.L();              // log
 * X.D&&X.V(_=``,eval(_));  // var-name=var-value
 * X.D&&X.T(_=``,eval(_));  // var-name=var-value + trace-info
 */
static D = false; // D)ebugP?


/**
 * Stringify an object
 * @param {*} data to be stringified
 * @param {*} opts {cmp: ()=>{}, cycles:boolean}
 * @returns string-exp of object
 */
static Strfy(data, opts = undefined) {

  if (!opts) opts = {};
  if (typeof opts === 'function')  opts = { cmp: opts };
  let cycles = (typeof opts.cycles === 'boolean') ? opts.cycles : false;

  let cmp =
      opts.cmp
  &&  (
        (f)=>{
          return (node)=>{
            return (a, b)=>{
              let aobj = { key:a, value:node[a] };
              let bobj = { key:b, value:node[b] };
              return f(aobj, bobj);
            };
          };
        }
      )(opts.cmp)
  ;

  let seen = [];

  return (
    function strngfy(node) {
      if (node  &&  node.toJSON  &&  typeof node.toJSON === 'function') {
        node = node.toJSON();
      }

      if (node === undefined)  return;
      if (typeof node === 'number')  return isFinite(node) ? '' + node : 'null';
      if (typeof node !== 'object')  return JSON.stringify(node);
/**>/
JSON.stringify(
  value     :any
, replacer ?:(this :any, key :string, value :any)=>any
, space    ?:string|number
) :string
;
/**/
      let i, out;
      if (Array.isArray(node)) {
        out = '';
        for (i = 0;  i < node.length;  ++i) {
          if (i)  out += ',';
          out += strngfy(node[i]) || 'null';
        }
        return `[${out}]`;
      }

      if (node === null)  return 'null';

      if (seen.indexOf(node) !== -1) {
        if (cycles)  return JSON.stringify('__cycle__');
        throw new TypeError('Converting circular structure to JSON');
      }

      let seenIndex = seen.push(node) - 1;
      let keys = Object.keys(node).sort(cmp && cmp(node));
      out = '';
      for (i = 0;  i < keys.length;  ++i) {
        let key = keys[i];
        let value = strngfy(node[key]);

        if (!value)  continue;
        if (out) out += ',';
        out += `${JSON.stringify(key)}:${value}`;
      }
      seen.splice(seenIndex, 1);
      return `{${out}}`;
    }
  )(data);

}; // static Strfy = (data, opts)=>


/**
 * stringify args
 * @param  {...any} args for stringify
 * @returns concatenated string of args
 */
static S(...args) {
  let s = '';
  args.forEach((e)=>{
    //console.log('e', e);
    //console.log('s', s);
    s += `${(s === '') ? '' : ' '}${X.Strfy(e, {cycles:true})}`;
  });
  return s;
}


/**
 * make string for debug-print
 * @param {*} lvl call-stack pos
 * @param  {...any} args to print
 * @returns 
 */
static DbgS(lvl, ...args) {
  const e = (new Error()).stack.split("\n");
  //console.log('e:', e);

  /**>/
  const re =
    /^\s+at\s+(?<fnn>\S+)\s+\(.+\/(?<fln>.+):(?<lno>\d+):(?<cno>\d+)\)$/
  ;
  /**/
  /**/
  const re = new RegExp(''
  + `^\\s+at\\s+`
  + `(?<fnn>\\S+)\\s+`
  + `\\(.+/`
    + `(?<fln>.+):`
    + `(?<lno>\\d+):`
    + `(?<cno>\\d+)`
  + `\\)$`
  );
  /**/

  const g = e?.[lvl]?.match(re)?.groups;
  //console.log('g', g);

  const fnn = g?.fnn ?? 'na';
  const fln = g?.fln ?? 'na';
  const lno = g?.lno ?? '';
  const cno = g?.cno ?? '';

  let arg1 = '';
  args.forEach((e)=>(arg1 += ` ${e}`));
//args.forEach((e)=>(arg1 += ` ${X.Strfy(e, { cycles:true })}`));

  return `${fln}(${lno}:${cno})${fnn}:${arg1}`;
}


static _L(lvl, ...args) {
  console.log(X.DbgS(lvl, ...args));
}


static _V(lvl, vn, vv, ...args) {
  const _vv = X.Strfy(vv, { cycles:true }); // 循環参照回避

  X._L(lvl, ((vn === vv) ? vn : `${vn}=${_vv}`), ...args);
}

/**
 * console.log
 * @param  {...any} args 
 * @returns 
 */
//static L(...args) { if (!X.D) return;  X._L(3, ...args); }
static L(...args) { if (!X.D) return;  X._L(4, ...args); }

/**
 * var-name:var-value log() : if(X.D){_=``;X.V(_,eval(_))}
 * @param {*} vn 
 * @param {*} vv 
 * @param {...any} args 
 * @returns 
 */
static V(vn, vv, ...args) {
  if (!X.D) return;
//X._V(4, vn, vv, ...args);
  X._V(5, vn, vv, ...args);
}
  // var-name=var-value + trace-info

/**
 * var-name:var-value log()w/trace : if(X.D){_=``;X.T(_,eval(_))}
 * @param {*} vn 
 * @param {*} vv 
 * @param  {...any} args 
 * @returns 
 */
static T(vn, vv, ...args) {
  if (!X.D) return;
//X._V(4, vn, vv,  ...args);
  X._V(5, vn, vv,  ...args);
  console.trace();
}


//..*....1....*....2....*....3....*....4....*....5....*....6....*....7....*....8
/// GET / POST interface

/**
 * $_GETs obj of current access
 * @returns { key:value,... } obj of $_GET
 */
static GetGETs() {
  const sch = window.location.search;
//const sch = globalThis.location.search;
  const pairs = sch.substring(1).split('&');

  const ret = {};
  pairs.forEach((pair)=>{
  //X.D&&X.V(_=`pair`,eval(_));
    const e = pair.split('=');
    const k = decodeURIComponent(e[0]);
    const v = decodeURIComponent(e[1]);
    ret[k] = v;
  });
  //console.log('GetGETs:', ret);
  return ret;
} // static GetGETs()


//..*....1....*....2....*....3....*....4....*....5....*....6....*....7....*....8
/// DATE-TIME

/**
 * DateTime to String
 * @param {*} dt Date
 * @param {*} fmt format
 * @returns string on dt
 */
static Dt2s(dt = new Date(), fmt = '`yy/OO/DDww-HH:II:SS') {
  let yr = dt.getFullYear();
  let mo = dt.getMonth() + 1;
  let dy = dt.getDate();
  let hr = dt.getHours();
  let mi = dt.getMinutes();
  let sc = dt.getSeconds();

  let ms = dt.getTime() % 1000;  ms = `00${ms}`.slice(-3);
  let ys = yr - 1900;  if (99 < ys) ys -= 100;
  let dw = dt.getDay();

  ys = `0${ys}`.slice(-2);  mo = `0${mo}`.slice(-2);  dy = `0${dy}`.slice(-2);
  hr = `0${hr}`.slice(-2);  mi = `0${mi}`.slice(-2);  sc = `0${sc}`.slice(-2);

  return fmt
    .replaceAll('YYYY', yr)
    .replaceAll('yy'  , ys)
    .replaceAll('OO'  , mo)
    .replaceAll('DD'  , dy)
    .replaceAll('ww'  , 'nmewhfa'[dw])
    .replaceAll('HH'  , hr)
    .replaceAll('II'  , mi)
    .replaceAll('SS'  , sc)
    .replaceAll('mmm' , ms)
  ;
} // static Dt2s()


/**
 * DateTime span to string
 * @param {*} dt9 end-date-time
 * @param {*} dt0 start-date-time
 * @param {*} fmt format
 * @returns string on (dt9 - dt0)
 */
static DtDif2s(dt9, dt0 = new Date(), fmt = 'DYdH2hM2mS2sL3') {
                                    //fmt = 'WKwDDdH2hM2mS2sL3'
  //X.D&&X.V(_=`dt9`,eval(_));
  //X.D&&X.V(_=`dt0`,eval(_));
  const MS_SC = 1000;
  const SC_MN =   60;
  const MN_HR =   60;
  const HR_DY =   24;
  const DY_WK =    7;

  const mss = dt9.getTime() - dt0.getTime();
  const scs = Math.floor(mss / MS_SC);
  const mns = Math.floor(scs / SC_MN);
  const hrs = Math.floor(mns / MN_HR);
  const dys = Math.floor(hrs / HR_DY);
  const wks = Math.floor(dys / DY_WK);

  const ms = mss - scs * MS_SC;
  const sc = scs - mns * SC_MN;
  const mn = mns - hrs * MN_HR;
  const hr = hrs - dys * HR_DY;
  const dy = dys - wks * DY_WK;

  const ms3 = `00${ms}`.slice(-3);
  const sc2 =  `0${sc}`.slice(-2);
  const mn2 =  `0${mn}`.slice(-2);
  const hr2 =  `0${hr}`.slice(-2);
//const dy2 =  `0${dy}`.slice(-2);

  return fmt
    .replaceAll('LS', mss)
    .replaceAll('L3', ms3)
    .replaceAll('LL', ms)

    .replaceAll('SC', scs)
    .replaceAll('S2', sc2)
    .replaceAll('SS', sc)

    .replaceAll('MN', mns)
    .replaceAll('M2', mn2)
    .replaceAll('MM', mn)

    .replaceAll('HR', hrs)
    .replaceAll('H2', hr2)
    .replaceAll('HH', hr)

    .replaceAll('DY', dys)
  //.replaceAll('D2', dy2)
    .replaceAll('DD', dy)

    .replaceAll('WK', wks)
  ;
} // static DtDif2s()


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
} // export default class X

/**>/
import X from './X.js';  let _;  X.D = true;  // for js/ts
import X from 'common/X';  let _;  X.D = true;  // for ts
/*eslint-disable no-eval*/

/*
X.D&&X.L();              // log
X.D&&X.V(_=``,eval(_));  // var-name=var-value
X.D&&X.T(_=``,eval(_));  // var-name=var-value + trace-info
*/
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// end of X.js
