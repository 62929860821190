/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  hdrbar/LocalIndCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import { Link, useLocation } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
//import { useDispatch, useSelector } from 'react-redux';
//import { slide as Menu } from 'react-burger-menu';

import LgG from '../../_img/logo_g.svg';
import LgW from '../../_img/logo_w.svg';

import StN from '../../_img/sns_tw_n.svg';
import SfN from '../../_img/sns_fb_n.svg';
import SiN from '../../_img/sns_ig_n.svg';

import MtF from '../../_img/mlk_top.svg';
import MtN from '../../_img/mlk_top_on.svg';
import MaF from '../../_img/mlk_about.svg';
import MaN from '../../_img/mlk_about_on.svg';
import MpF from '../../_img/mlk_project.svg';
import MpN from '../../_img/mlk_project_on.svg';
import MfF from '../../_img/mlk_workflow.svg';
import MfN from '../../_img/mlk_workflow_on.svg';
//import MwF from '../../_img/mlk_works.svg';
//import MwN from '../../_img/mlk_works_on.svg';
import MsF from '../../_img/mlk_service.svg';
import MsN from '../../_img/mlk_service_on.svg';
import McF from '../../_img/mlk_contact.svg';
import McN from '../../_img/mlk_contact_on.svg';

//import { LocalIndcAsyncThunk } from '.';
//import logo from './logo.svg';
//import './App.scss';
//import TheStore from '../../app/store';
//import type { LocalIndStateT } from '.';
//import { LocalIndcSlice } from '.';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export const SnsLnks = [
  { cn:'tw', sc:StN, nm:'twitter',   ul:'https://twitter.com/reiro_kyoto' }
, { cn:'fb', sc:SfN, nm:'facebook',  ul:'https://www.facebook.com/reirokyoto/' }
, { cn:'ig', sc:SiN, nm:'instagram', ul:'https://www.instagram.com/reiro_kyoto/' }
];  


/// render

function _SnsLksCpF(sp = true) {
  return (
  <div className={(sp) ? 'sns-lnk sns-sp-lnk' : 'sns-lnk sns-pc-lnk'}>
  {
    SnsLnks.map(
      e => (
        <a className={e.cn} href={e.ul} key={e.cn}
          target='_blank' rel='noreferrer'
        >
          <img src={e.sc} alt={'sns-' + e.nm}/>
        </a>
      )
    )
  }
  </div>
  );
}
function SnsLksSpCpF() { return _SnsLksCpF(true ); }
function SnsLksPcCpF() { return _SnsLksCpF(false); }

/**/
const SiteLnks = [
  { sn:MtN, sf:MtF, nm:'top',      ul:'/'          }
, { sn:MaN, sf:MaF, nm:'about',    ul:'/about'     }
, { sn:MpN, sf:MpF, nm:'project',  ul:'/project'   }
, { sn:MfN, sf:MfF, nm:'workflow', ul:'/workflow'  }
//, { sn:MwN, sf:MwF, nm:'works',    ul:'/works'     }
, { sn:MsN, sf:MsF, nm:'service',    ul:'/service' }
, { sn:McN, sf:McF, nm:'contact',  ul:'/contact'   }
];
/**/


function BgrMnuCpF() {
  return (
  <Menu right noOverlay>
    <Link to='/' className='menu-item'>
      <img className='lgo-hmu' src={LgW} alt='153inc.'/>
    </Link>
  {
    SiteLnks.map(
      e => (
        <Link to={e.ul} className='menu-item' key={e.nm}>
          {'/ ' + e.nm}
        </Link>
      )
    )
  }
    <a id='reiro-shp' className='menu-item' target='_blank' rel='noreferrer'
      href='https://reiro.theshop.jp'
    >
      reiro online shop
    </a>
    {/* <a id='reiro-web' className='menu-item' target='_blank' rel='noreferrer'
      href='https://kyoto-reiro.com'
    >
      reiro official site
    </a> */}

  </Menu>
  );
}

function PgLnksCpF() {
  const pth = useLocation().pathname;
//console.log('pth', pth);

  return (
  <div className='pg-lnks'>
  {
    SiteLnks.map(
      e => (
        <Link to={e.ul} key={e.nm}>
          <img src={(pth === e.ul) ? e.sn : e.sf} alt={'lnk-' + e.nm} />
        </Link>
      )
    )
  }
  </div>
  );
}


export function HdrBarCpF() {
//const dspt = useDispatch();
//const f2dp = ()=>dspt(LocalIndcSlice.actions.run_in_local);
//const f2dp = ()=>dspt(LocalIndcAsyncThunk());
//f2dp();

//const in_local = useSelector((state :any) => state.local_ind.run_in_local);
//if (!in_local)  return (<></>);
/**/
//<img src={logo} className='App-logo' alt='logo'/>

  return (
  <header id='hdr-bar' className='hdr-bar'>

    <div className='pc'>
      <div className='h-logo'><Link to='/'><img src={LgG} alt=''/></Link></div>
      <div className='h-lnks'><PgLnksCpF/></div>
      <div className='h-snss'><SnsLksPcCpF/></div>
    </div>{/*.pc*/}

    <div className='sp'>
      <div className='h-snss'><SnsLksSpCpF/></div>
      <div className='h-logo'><Link to='/'><img src={LgG} alt=''/></Link></div>
      <div className='h-lnks'><BgrMnuCpF/></div>
    </div>{/*.pc*/}

  </header>
  );
}
export default HdrBarCpF;

/**>/
/**/


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// hdrbar/LocalIndCpF.tsx
