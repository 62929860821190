/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  AppCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
//import logo from './logo.svg';

//import { BrowserRouter, Route, Switch } from "react-router-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import 'app/App.scss';

import { LocalIndcCpF  } from 'common/localindc';
import { HdrBarCpF     } from 'common/hdrbar';
import { FtrBarCpF     } from 'common/ftrbar';

import { PgTopCpF      } from 'app/top';
import { PgAboutCpF    } from 'app/about';
import { PgProjectCpF  } from 'app/project';
import { PgWorkflowCpF } from 'app/workflow';
//import { PgWorksCpF    } from 'app/works';
import { PgServiceCpF  } from 'app/service';
import { PgContactCpF  } from 'app/contact';
//import { OrdersCpF } from 'features/orders';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export default function AppCpF() {
  return (<>
    <LocalIndcCpF/>
    <div className='app'>
      <div className='cntnt'>
        <HashRouter hashType='noslash'>
          <HdrBarCpF/>

          <Switch>
            <Route exact path='/'         component={PgTopCpF}      />
            <Route exact path='/about'    component={PgAboutCpF}    />
            <Route exact path='/project'  component={PgProjectCpF}  />
            <Route exact path='/workflow' component={PgWorkflowCpF} />
  {/**>/}
            <Route exact path='/works'    component={PgWorksCpF}    />
  {/**/}
            <Route exact path='/service'  component={PgServiceCpF}  />
            <Route exact path='/contact'  component={PgContactCpF}  />
  {/**>/}
            <Route component={Page404} />
  {/**/}
          </Switch>

          <FtrBarCpF/>
        </HashRouter>
      </div>{/*.cntnt*/}
    </div>{/*.app*/}
  </>);
}

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// AppCpF.tsx
