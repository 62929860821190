/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  contact/PgContactCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
//import logo from './logo.svg';

import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';
//import { OrdersCpF } from 'features/orders';
import TrG from '_img/tra_g.svg';
import TrW from '_img/tra_w.svg';
import Itl from '_img/icn_tel.svg';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function PgContactCpF() {
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , 'お問い合わせ : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , 'お仕事のご依頼・ご相談、お見積などお気軽にお問い合わせください。'
  // GaTrk(Ga4TrkId
  // , 'お問い合わせ : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  // , 'お仕事のご依頼・ご相談、お見積などお気軽にお問い合わせください。お電話でのご質問・ご意見も受け付けております。'
  );

  return (
    <div id='pg-contact' className='pg-contact'>

      <h1 className='pg-ttl arwbnd gry'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='ttl1'>contact</div>
        <div className='ttl2'>お問い合わせ</div>

        <div className='desc'>
               お仕事のご依頼・ご相談、お見積などお気軽に<br className='sm-vis'/>お問い合わせください。
          {/* <br/>お電話でのご質問・ご意見も<br className='xs-vis'/>受け付けております。 */}
        </div>
      </h1>

      <div className='arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**/}  <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}

        <div className='ttl f-sgb f7p'
          style={{ color:'#666', maxWidth:'90vw', textAlign:'center' }}
        >
          入力フォームからの<br className='sm-vis'/>お問い合わせはコチラ
        </div>
      </div>

      <div className='arwbnd gry'>
{/**/}  <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
      </div>

      <div className='arwbnd gry' style={{paddingRight:'3.0rem'}}>
{/**>/}  <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
                            {/*scrolling='no'*/}
        <iframe id='cntctfrm' title='contact' frameBorder='no'
          src='https://igf.sakai-h.co.jp/_cf_/'
          style={{ width:'100%', margin: '2.0rem' }}
        ></iframe>
      </div>
{/*
      <div className='arwbnd wht'>
        <div className='trg'><img src={TrG} alt='gry'/></div>

        <div className='ttl f-sgb f7p' style={{ color: '#666' }}>
          お電話でのお問い合わせはコチラ
        </div>
      </div>

      <div className='arwbnd gry'>
        <div className='trw'><img src={TrW} alt='wht'/></div>

        <div className='tel'>
          <a href='tel:0752547788'>
            <img src={Itl} alt='お電話 tel:075-254-7788'/>
          </a>
          <div className='no'>
            <a href='tel:0752541050'>075-254-1050</a>
          </div>
        </div>
        <div className='f-sgb'>
          受付時間／平日 9：30〜18：30<br className='sm-vis'/>（年末年始・祝祭日をのぞく）
        </div>
      </div>
*/}

    </div>
  );
}
export default PgContactCpF;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// contact/PgContactCpF.tsx
