/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  project/PgProjectCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
import { SnsLnks } from 'common/hdrbar';
import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';
//import logo from './logo.svg';
//import { OrdersCpF } from 'features/orders';
//import TrG from '_img/tra_g.svg';
import BnP from '_img/bnr_ppr.jpg';
import Lrr from '_img/logo_reiro.svg';
import Irr from '_img/ico_reiro.svg';
import BrS from '_img/btn_reiro_shp.svg';
import BrW from '_img/btn_reiro_web.svg';
import SfC from '_img/sns_fb_c.svg';
import SiC from '_img/sns_ig_c.svg';
import StC from '_img/sns_tw_c.svg';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function PgProjectCpF() {
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , 'プロジェクト - 零露(れいろ) : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , '素敵な日本のあれこれをオリジナル文具として販売するオンラインショップを立ち上げました。「零露（れいろ）」とは、露がしたたり落ちることを意味します。想いを綴る大切な時間に、日々の暮らしに、露のようなきらめきを。そんな文具を京都から発信しています。'
  );

  return (<>
    <div id='pg-project' className='pg-project'>

      <div className='x-fit'>
        <img src={BnP} alt='bnr-ppr' width='2000' height='1053'/>
      </div>

      <h1 className='pg-ttl arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='ttl1'>project</div>
        <div className='ttl2'>プロジェクト</div>

        <div className='my-6'>
          <img src={Lrr} style={{width:'100%', height:'auto'}}
            alt='零露(れいろ) 素敵な日本のあれこれをオリジナル文具として販売するオンラインショップ'
            width='246' height='350'
          />
        </div>

        <div className='desc'>
               素敵な日本のあれこれを<br className='sm-vis'/>オリジナル文具として販売する
          <br/>オンラインショップを立ち上げました。
          <br/>「零露（れいろ）」とは、<br className='sm-vis'/>露がしたたり落ちることを意味します。
          <br/>想いを綴る大切な時間に、日々の暮らしに、<br className='sm-vis'/>露のようなきらめきを。
          <br/>そんな文具を京都から発信しています。
        </div>

        <div className='stmp mt-6'>
          <img src={Irr} alt='bnr-stmp' width='145' height='100'/>
        </div>
        <div className='lnks2shp mt-6'>
          <div className='rr-shp'>
            <a target='_blank'  rel='noreferrer'
              href='https://reiro.theshop.jp'
            >
              <img src={BrS} alt='btn-shp' width='200' height='50'/>
            </a>
          </div>
          {/* <div className='rr-web'>
            <a target='_blank' rel='noreferrer'
              href='https://kyoto-reiro.com'
            >
              <img src={BrW} alt='btn-web' width='200' height='50'/>
            </a>
          </div> */}
        </div>

        <div className='lnks2sns mt-6'>
          <span className='sns-tw'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[0].ul}
            >
              <img src={StC} alt='btn-sns-tw' width='100' height='100'/>
            </a>
          </span>
          <span className='sns-fb'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[1].ul}
            >
              <img src={SfC} alt='btn-sns-fb' width='100' height='100'/>
            </a>
          </span>
          <span className='sns-ig'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[2].ul}
            >
              <img src={SiC} alt='btn-sns-ig' width='100' height='100'/>
            </a>
          </span>
        </div>

      </h1>

    </div>{/*#pg-project*/}
  </>);
}
export default PgProjectCpF;

/**>/
        <div className='lnks2sns mt-6'>
          <div className='sns-tw'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[0].ul}
            >
              <img src={StC} alt='bnr-ppr'/>
            </a>&emsp;&emsp;
          </div>
          <div className='sns-fb'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[1].ul}
            >
              <img src={SfC} alt='bnr-ppr'/>
            </a>&emsp;&emsp;
          </div>
          <div className='sns-ig'>
            <a target='_blank' rel='noreferrer'
              href={SnsLnks[2].ul}
            >
              <img src={SiC} alt='bnr-ppr'/>
            </a>
          </div>
        </div>
/**/


//ppr::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// project/PgProjectCpF.tsx
