/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  about/PgAboutCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';

//import React from 'react';
//import logo from './logo.svg';
//import { OrdersCpF } from 'features/orders';
//import TrG from '_img/tra_g.svg';
//import TrW from '_img/tra_w.svg';
import BnE from '_img/bnr_env.jpg';
import TxH from '_img/txt_hsd.svg';
//import TxC from '../../_img/txt_cmpny.svg';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function PgAboutCpF() {
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , '153のこと : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , '153という数字はハーシャッド数です。ハーシャッド数はサンスクリット語で「喜び」と「与える」を意味する言葉が語源だそう。153という社名は「喜び」を「与える」デザインを発信していきたいという想いが込められています。'
  );

  return (<>
    <div id='pg-about' className='pg-about'>

      <div className='x-fit'>
        <img src={BnE} alt='bnr-env' width='2000' height='1053'/>
      </div>

      <h1 className='pg-ttl arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='ttl1'>about 153</div>
        <div className='ttl2'>153 のこと</div>

        <div className='desc'>
               153という数字はハーシャッド数です。
          <br/>ハーシャッド数はサンスクリット語で<br className='sm-vis'/>「喜び」と「与える」を意味する言葉が<br className='xs-vis'/>語源だそう。
          <br/>153という社名は<br className='xs-vis'/>「喜び」を「与える」デザインを
          <br/>発信していきたいという想いが<br className='xs-vis'/>込められています。
        </div>

        <div style={{marginTop: '2.0rem'}}>
          <img src={TxH} style={{maxWidth:'95vw'}}
            alt='ハーシャッド数とは、各位の数字の和が元の数の約数に含まれる自然数。153の各位の和は1＋5＋3＝9となり、9は153の約数であることから、153はハーシャッド数となります。'
          />
        </div>
      </h1>

      <div className='pnl-cmpny'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div style={{height:'1.0rem'}}></div>
        <div className='ttl1'>company</div>
        <div className='ttl2'>会社概要</div>

        <div className='tbl'>

          <div className='grd'>
            <div className='r1c1 l'>
              <span>社</span><span>名</span>{/*NG:for safari*/}
            </div>
            <div className='r1c2 r'>株式会社153</div>

            <div className='r2c1 l'>
              <span>設</span><span>立</span>
            </div>
            <div className='r2c2 r'>2017年</div>

            <div className='r3c1 l'>
              <span>代</span><span>表</span><span>取</span><span>締</span><span>役</span>
            </div>
            <div className='r3c2 r'>氏家 淳子/Ujike Junko</div>

            <div className='r4c1 l'>
              <span>業</span><span>務</span><span>内</span><span>容</span>
            </div>
            <div className='r4c2 r'>
                  ●グラフィックデザイン
              <br/>［会社や店舗のパンフレット･名刺･DM･SPツールなどの印刷物の制作］
              <br/>●WEB
              <br/>［新規立ち上げ･リニューアル･LP･キャンペーンサイトなどの制作］
            </div>

            <div className='r5c1 l'>
              <span>所</span><span>在</span><span>地</span>
            </div>
            <div className='r5c2 r'>
                  〒615-0001
              <br/>京都府京都市右京区西院上今田町15-802

          {/* 〒604-8181
              <br/>京都府京都市中京区綿屋町528-701
              <br/>TEL：075-254-1050
              <span className='sm-non'>/</span>
              <br className='sm-vis'/>
              FAX：075-254-1060
          */}

              <div className='map-tbl'>
{/**>/}
                <iframe title='map-tbl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5849.299270554908!2d135.75519784206952!3d35.01488299062821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001087dd4cd432b%3A0xb0f3d722fd0d9235!2z44CSNjA0LTAwMDUg5Lqs6YO95bqc5Lqs6YO95biC5Lit5Lqs5Yy6546J5qSN55S677yS77yS77yZ!5e0!3m2!1sja!2sjp!4v1631454881553!5m2!1sja!2sjp" style={{border:0}} loading="lazy"></iframe>
                <iframe title='map-tbl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1715.162316926324!2d135.76073483354907!3d35.01026940297445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600108857ffa960b%3A0x74a5d592d166ef97!2z44CSNjA0LTgxODEg5Lqs6YO95bqc5Lqs6YO95biC5Lit5Lqs5Yy657a_5bGL55S677yV77yS77yY4oiS77yX77yQ77yR!5e0!3m2!1sja!2sjp!4v1673406566135!5m2!1sja!2sjp" style={{border:0}} loading="lazy"></iframe>
{/**/}
                <iframe title='map-tbl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d277.7222233351951!2d135.73151682520873!3d35.00822191532549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f100!3m3!1m2!1s0x600107f1a2ee0999%3A0xc66e285de0a41f30!2z44Kv44Oq44K544K_44Or44Kw44Op44Oz44OE6KW_5aSn6Lev5LiJ5p2h!5e0!3m2!1sja!2sjp!4v1717469750601!5m2!1sja!2sjp" style={{border:0}} loading="lazy"></iframe>
              </div>
            </div>
          </div>{/*.grd*/}

          <div className='map-ind'>
{/**>/}
              <iframe title='map-ind' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5849.299270554908!2d135.75519784206952!3d35.01488299062821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001087dd4cd432b%3A0xb0f3d722fd0d9235!2z44CSNjA0LTAwMDUg5Lqs6YO95bqc5Lqs6YO95biC5Lit5Lqs5Yy6546J5qSN55S677yS77yS77yZ!5e0!3m2!1sja!2sjp!4v1631454881553!5m2!1sja!2sjp" style={{ border:0}} loading="lazy"></iframe>
              <iframe title='map-ind' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1715.162316926324!2d135.76073483354907!3d35.01026940297445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600108857ffa960b%3A0x74a5d592d166ef97!2z44CSNjA0LTgxODEg5Lqs6YO95bqc5Lqs6YO95biC5Lit5Lqs5Yy657a_5bGL55S677yV77yS77yY4oiS77yX77yQ77yR!5e0!3m2!1sja!2sjp!4v1673406566135!5m2!1sja!2sjp" style={{ border:0}} loading="lazy"></iframe>
{/**/}
                <iframe title='map-ind' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d277.7222233351951!2d135.73151682520873!3d35.00822191532549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f100!3m3!1m2!1s0x600107f1a2ee0999%3A0xc66e285de0a41f30!2z44Kv44Oq44K544K_44Or44Kw44Op44Oz44OE6KW_5aSn6Lev5LiJ5p2h!5e0!3m2!1sja!2sjp!4v1717469750601!5m2!1sja!2sjp" style={{border:0}} loading="lazy"></iframe>
          </div>
        </div>

      </div>
    </div>{/*#pg-about*/}
  </>);
}
export default PgAboutCpF;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// about/PgAboutCpF.tsx
