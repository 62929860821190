/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  workflow/PgWorkflowCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
import { Link } from "react-router-dom";

import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';
//import logo from './logo.svg';
//import { OrdersCpF } from 'features/orders';
import TrG from '_img/tra_g.svg';
import TrW from '_img/tra_w.svg';

import W1s from '_img/txt_wf01_sp.svg';
import W2s from '_img/txt_wf02_sp.svg';
import W3s from '_img/txt_wf03_sp.svg';
import W4s from '_img/txt_wf04_sp.svg';
import W5s from '_img/txt_wf05_sp.svg';
import W6s from '_img/txt_wf06_sp.svg';

import W1p from '_img/txt_wf01_pc.svg';
import W2p from '_img/txt_wf02_pc.svg';
import W3p from '_img/txt_wf03_pc.svg';
import W4p from '_img/txt_wf04_pc.svg';
import W5p from '_img/txt_wf05_pc.svg';
import W6p from '_img/txt_wf06_pc.svg';

import BnC from '_img/btn_contact.svg';
//import BnW from '_img/btn_works.svg';

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8

const _Dsc = [
/*0*/  '' // sentinel
/*1*/, '01:ご相談:まずはお客さまの悩みやご要望を直接お伺いできるよう、日時の調整をいたします。'
/*2*/, '02:お打ち合わせ:お客さまの制作したいもの・コンセプト・スケジュール・全体ご予算などお話ししてください。'
/*3*/, '03:お見積・ご提案:お見積を提出したのち、アイデアをカタチにしてご提案いたします。'
/*4*/, '04:制作:お客さまのご希望・ご指示内容とすり合わせ、デザインを確定していきます。'
/*5*/, '05:印刷・コーディング:デザイン確定後、最終チェックののち、印刷会社へ入稿やコーディング作業に入ります。'
/*6*/, '06:納品:印刷物の納品やテストサイトをご確認いただいたのち、HPを公開いたします。'
];


export function PgWorkflowCpF() {
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , '制作の流れ : 153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , '153inc.では、お客さまのご意向に丁寧に寄り添いながら制作を進めてまいります。contactよりお問い合わせください。業務委託をお考えの方もお気軽にご連絡ください。'
  );

  return (
    <div id='pg-workflow' className='pg-workflow'>

      <h1 className='arwbnd gry'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='ttl1'>workflow</div>
        <div className='ttl2'>制作の流れ</div>

        <div className='desc'>
               153inc.では、お客さまのご意向に<br className='sm-vis'/>丁寧に寄り添いながら制作を進めてまいります。
          <br/>contactよりお問い合わせください。
          <br/>業務委託をお考えの方も<br className='sm-vis'/>お気軽にご連絡ください。
        </div>
      </h1>

      <div className='arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**/}  <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf1 sp'>
          <img src={W1s} alt={_Dsc[1]} width='464' height='211'/>
        </div>
        <div className='txt-wf1 pc'>
          <img src={W1p} alt={_Dsc[1]} width='880' height='145'/>
        </div>

        <div className='btn-contact'>
          <Link to='/contact'>
            <img src={BnC} alt=''/>
          </Link>
        </div>
      </div>

      <div className='arwbnd gry'>
{/**/}  <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf2 sp'>
          <img src={W2s} alt={_Dsc[2]} width='464' height='211'/>
        </div>
        <div className='txt-wf2 pc'>
          <img src={W2p} alt={_Dsc[2]} width='880' height='145'/>
        </div>
      </div>

      <div className='arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**/}  <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf3 sp'>
          <img src={W3s} alt={_Dsc[3]} width='464' height='211'/>
        </div>
        <div className='txt-wf3 pc'>
          <img src={W3p} alt={_Dsc[3]} width='880' height='145'/>
        </div>
      </div>

      <div className='arwbnd gry'>
{/**/}  <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf4 sp'>
          <img src={W4s} alt={_Dsc[4]} width='464' height='211'/>
        </div>
        <div className='txt-wf4 pc'>
          <img src={W4p} alt={_Dsc[4]} width='880' height='145'/>
        </div>
      </div>

      <div className='arwbnd wht'>
{/**>/} <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**/}  <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf5 sp'>
          <img src={W5s} alt={_Dsc[5]} width='464' height='211'/>
        </div>
        <div className='txt-wf5 pc'>
          <img src={W5p} alt={_Dsc[5]} width='880' height='145'/>
        </div>
      </div>

      <div className='arwbnd gry'>
{/**/}  <div className='trw'><img src={TrW} alt='wht'/></div>{/**/}
{/**>/} <div className='trg'><img src={TrG} alt='gry'/></div>{/**/}
        <div className='txt-wf6 sp'>
          <img src={W6s} alt={_Dsc[6]} width='464' height='211'/>
        </div>
        <div className='txt-wf6 pc'>
          <img src={W6p} alt={_Dsc[6]} width='880' height='145'/>
        </div>
{/**>/}
        <div className='btn-works mt-6'>
          <Link to='/service'>
            <img src={BnW} alt='' width='880' height='145'/>
          </Link>
        </div>
{/**/}
      </div>
    </div>
  );
}
export default PgWorkflowCpF;


//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// workflow/PgWorkflowCpF.tsx
