/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  top/PgTopCpF.tsx
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
//import React from 'react';
import { GaTrk, Ga_TrkId, Ga4TrkId  } from 'common/gatrk';
import { SnsLnks } from 'common/hdrbar';

//import logo from './logo.svg';
import Bn0 from '_img/bnr_top0.svg';
import Bn1 from '_img/bnr_top1.svg';

import StF from '_img/sns_tw_f.svg';
import SfF from '_img/sns_fb_f.svg';
import SiF from '_img/sns_ig_f.svg';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


export function PgTopCpF() {
  GaTrk(Ga_TrkId);
  GaTrk(Ga4TrkId
  , '153inc. 京都を拠点に活動しているデザイン会社・株式会社153'
  , '153inc. 京都を拠点に活動しているデザイン事務所・株式会社153・〒615-0001 京都府京都市右京区西院上今田町15-802・会社概要・プロジェクト 零露(れいろ)・制作の流れ・業務内容・お問い合わせ'
  // , '153inc. 京都を拠点に活動しているデザイン事務所・株式会社153・〒604-8181 京都府京都市中京区綿屋町528-701・TEL：075-254-1050・FAX：075-254-1060・会社概要・プロジェクト 零露(れいろ)・制作の流れ・業務内容・お問い合わせ'
  );

  return (
    <div id='pg-top' className='pg-top'>
      <h1 id='top-bnr-0' className='top-bnr-0'>
        {/* <img src={Bn0}
          alt='153inc.は京都を拠点に活動しているデザイン会社です 株式会社153・〒604-8181 京都府京都市中京区綿屋町528-701・TEL：075-254-1050・FAX：075-254-1060'
        /> */}
        <img src={Bn0}
          alt='153inc.は京都を拠点に活動しているデザイン会社です 株式会社153・〒615-0001 京都府京都市右京区西院上今田町15-802'
        />
      </h1>

      <div id='top-bnr-oth'>
        <div className='top-bnr-1'>
          <img src={Bn1}
            alt='153inc.は京都を拠点に活動しているデザイン会社です。'
          />
        </div>

        <div className='top-snss'>
          <span className='sns-tw'>
            <a href={SnsLnks[0].ul} target='_blank' rel='noreferrer'
            >
              <img src={StF} alt='tw'/>
            </a>
          </span>
          <span className='sns-fb'>
            <a href={SnsLnks[1].ul} target='_blank' rel='noreferrer'
            >
              <img src={SfF} alt='fb'/>
            </a>
          </span>
          <span className='sns-ig'>
            <a href={SnsLnks[2].ul} target='_blank' rel='noreferrer'
            >
              <img src={SiF} alt='ig'/>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
export default PgTopCpF;

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// top/PgTopCpF.tsx
