/*::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
  index.tsx

[suffix]
A rray
  Ac Action
  Ak Action Kreator
B oolean
C ontainer-component
  Cc Container-Component
  Cp Presentational-Component
D
E
F unction
G :saGa
H ook
I nterface
J
K ey
L
M odule
N
O bject
P resentational-component
Q
R educer
S tring
T ype
U nion
V
W :middleWare
X lass
Y :generator/Yield-function
Z
::::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*:::*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import reportWebVitals from './common/reportWebVitals';

import TheStore from './app/store';
import AppCpF from './app/AppCpF';
//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8


ReactDOM.render(
  <React.StrictMode>
    <Provider store={TheStore}>
      <AppCpF/>
    </Provider>
  </React.StrictMode>

, document.getElementById('root')
);


/// ページ内リンク時にハンバーガーメニューが閉じないことへの対処
/// 同時にハンバーガーメニュー開放時にHボタンを非表示に

const obtn :any = document.getElementsByClassName('bm-burger-button')[0];
  // Hボタン外側
const bbtn = document.getElementById('react-burger-menu-btn')!
  // Hボタン内部 button
const xbtn = document.getElementById('react-burger-cross-btn')!
  // Hメニュー内クローズ button
const lnks = document.querySelectorAll('.menu-item');
  // Hメニュー内 各リンク

// Hメニューボタンクリックで自身を非表示
bbtn.addEventListener(
  'click'
, () => {
    obtn.style.visibility = 'hidden';
  }
, false
);

// Hメニュー内 各リンククリックで、Hメニュークローズボタン 押下
lnks.forEach(lnk => {
  lnk.addEventListener(
    'click'
  , () => {
      xbtn.click();
    }
  , false
  );
});

// Hメニュークローズボタン 押下時にHボタン再表示
xbtn.addEventListener(
  'click'
, () => {
    obtn.style.visibility = 'visible';
  }
, false
);


/// トップページ・fadein 初回一回のみ  // ref: App.scss
const tb0 = document.getElementById('top-bnr-0');
const oth = document.getElementById('top-bnr-oth');
const hdr = document.getElementById('hdr-bar');
const ftr = document.getElementById('ftr-bar');

if (tb0 && oth && hdr && ftr) {
  tb0.style.opacity = oth.style.opacity = '0';
  hdr.style.opacity = ftr.style.opacity = '0';

  tb0.classList.add('fadein1st');
  setTimeout(
    () => { tb0.style.opacity = '1';  tb0.classList.remove('fadein1st'); }
  ,  6000 // ttl-msec: dur1
  );
  oth.classList.add('fadein2nd');
  setTimeout(
    () => { oth.style.opacity = '1';  oth.classList.remove('fadein2nd'); }
  , 10000 // ttl-msec: dur2
  );
  hdr.classList.add('fadein3rd');  ftr.classList.add('fadein3rd');
  setTimeout(
    () => {
      hdr.style.opacity = '1';  hdr.classList.remove('fadein3rd');
      ftr.style.opacity = '1';  ftr.classList.remove('fadein3rd');
    }
  ,  9000 // ttl-msec: dur3
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//::*::::1::::*::::2::::*::::3::::*::::4::::*::::5::::*::::6::::*::::7::::*::::8
// index.tsx
